import React, { useEffect, useRef, useState } from 'react';
import './style.css';

interface FilterBoolProps {
  columnId: string;
  placeholder: string;
  trueLabel: string;
  falseLabel: string;
  setFilter: (columnId: string, filterValue: string) => void;
}

type SelectItem = {
  key: string;
  value: string;
};

export const FilterBool: React.FC<FilterBoolProps> = ({ columnId, placeholder, trueLabel, falseLabel, setFilter }) => {
  const [showSelector, setShowSelector] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(undefined);
  const selectorRef = useRef<HTMLUListElement | null>(null);
  const options: SelectItem[] = [
    { key: '', value: 'Tutti' },
    { key: 'true', value: trueLabel },
    { key: 'false', value: falseLabel },
  ];

  const handleSelectorClick = () => {
    setShowSelector(!showSelector);
  };

  const handleOptionClick = (option: string) => {
    setShowSelector(false);
    setFilter(columnId, option);
    setSelectedOption(option === '' ? undefined : option);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
      setShowSelector(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="filter">
      <div className="filter-selector">
        <div onClick={handleSelectorClick}>
          {placeholder}: {selectedOption === 'true' ? trueLabel : selectedOption === 'false' ? falseLabel : 'Tutti'}
        </div>
      </div>
      {showSelector && (
        <ul ref={selectorRef}>
          {options.map((option) => (
            <li key={option.value} style={{ cursor: 'pointer' }} onClick={() => handleOptionClick(option.key)}>
              {option.value}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
