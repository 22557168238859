import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { CompanyDetails, CompanyStatus } from '../../../models/Company';
import { getCompanyDetailsAPI } from '../../../services/companiesApi';
import { toastWarning } from '../../../utils/toastMethod';
import { useDispatch } from 'react-redux';
import { setCompanyDetails } from '../../../store/companySlice';
import { DashboardSkeleton } from '../../Skeleton/DashboardSkeleton';
import PageLayoutSkeleton from '../PageLayout/skeleton';
import SideBarSkeleton from '../SideBar/skeleton';
import SideBar from '../SideBar';
import './style.css';

export default function CompanyLayout() {
  const { companyId } = useParams<string>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [companyDetailsFetched, setCompanyDetailsFetched] = useState<boolean>(false);

  useEffect(() => {
    getCompanyDetailsAPI(companyId)
      .then((result: CompanyDetails) => {
        if (result.status === CompanyStatus.PENDING) navigate(`/company/${companyId}/stripe/account`);
        dispatch(setCompanyDetails({ companyDetails: result }));
        setCompanyDetailsFetched(true);
      })
      .catch(() => toastWarning('Errore nel carimento delle informazioni della tua attività. Riprova!'));
  }, [companyId, dispatch, navigate]);

  return (
    <div className="company-layout-container">
      <>
        <aside className="sidebar">{companyDetailsFetched ? <SideBar /> : <SideBarSkeleton />}</aside>
        <div className="company-main-content">
          {companyDetailsFetched ? (
            <Outlet />
          ) : (
            <PageLayoutSkeleton>
              <DashboardSkeleton />
            </PageLayoutSkeleton>
          )}
        </div>
      </>
    </div>
  );
}
