import React, { useState } from 'react';
import useCheckMobileResolution from '../../hook/useCheckMobileResolution';
import { uploadStripeAccountLinkUrlAPI } from '../../services/companiesApi';
import { useParams } from 'react-router-dom';
import { CreateStripeAccountLinkResult } from '../../models/Company';
import { toastSuccess, toastWarning } from '../../utils/toastMethod';
import { useAppSelector } from '../../hook/useReduxHooks';
import { RootState } from '../../store/store';
import PayoutBalance from './PayoutBalance';

export default function RedeemGiftCards() {
  const { companyId } = useParams<string>();
  const stipeAccountId = useAppSelector((state: RootState) => state.company.stripeAccountId);
  const stripeBalance = useAppSelector((state: RootState) => state.company.stripeBalance);
  const isMobile = useCheckMobileResolution();
  const [show, setShow] = useState(false);
  const priceMultiplier = 100;

  const handleUpdateStripeProfile = () => {
    uploadStripeAccountLinkUrlAPI(companyId, stipeAccountId, {
      refreshUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
      returnUrl: `${process.env.REACT_APP_BASE_URL}/company/${companyId}/dashboard?stripe-updated=true`,
    })
      .then((redirectUrl: CreateStripeAccountLinkResult) => {
        if (redirectUrl) {
          toastSuccess('Stai per essere reindirizzato su Stripe.');
          window.location.href = redirectUrl.url;
        }
      })
      .catch(() =>
        toastWarning("Problemi durante l'aggiornamento del tuo profilo Stripe. Riprova o contatta l'assistenza.")
      );
  };

  return (
    <>
      <div
        style={{
          width: '100%',
          backgroundColor: '#F0F9D7',
          borderRadius: '10px',
          border: '1px solid #D4D4D4',
          padding: '20px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          marginBottom: '20px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '220px',
            height: '65px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            marginRight: '20px',
            fontWeight: '700',
            fontSize: '32px',
          }}
        >
          {stripeBalance / priceMultiplier}€
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginRight: 'auto',
            marginTop: isMobile ? '20px' : '',
          }}
        >
          <div style={{ fontWeight: '600' }}>Riscuoti i tuoi buoni</div>
          <div>{"Premi sul pulsante 'Riscuoti' per trasferire il denaro sul tuo conto corrente."}</div>
        </div>
        <div
          style={{
            marginTop: isMobile ? '20px' : 'auto',
            marginLeft: '10px',
            display: 'flex',
            flexDirection: 'column',
            width: '215px',
          }}
        >
          <button
            type="button"
            className="btn btn-primary"
            style={{ marginBottom: '10px' }}
            onClick={() => setShow(true)}
            disabled={stripeBalance > 0 ? false : true}
          >
            Riscuoti
          </button>
          <button type="button" className="btn btn-primary" onClick={handleUpdateStripeProfile}>
            Aggiorna profilo Stripe
          </button>
        </div>
      </div>

      <PayoutBalance show={show} setShow={setShow} />
    </>
  );
}
