import React, { useEffect, useState } from 'react';
import { GiftCardsDetails, RedeemDetails } from '../../models/GiftCard';
import { getRedeemGiftCardsDetailsAPI } from '../../services/giftCardApi';
import { useParams } from 'react-router-dom';
import { toastWarning } from '../../utils/toastMethod';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { GlobalSearch } from '../GlobalSearch';
import { FilterContainer } from '../Filter/FilterContainer';
import { Filter } from '../Filter/Filter';
import { FilterBool } from '../Filter/FilterBool';
import CustomTable from '../CustomTable';

type FilterOptions = {
  redeemedBy: string[];
  shopName: string[];
};

enum TransferredType {
  ACCOUNTED = 'Contabilizzato',
  UNACCOUNTED = 'Non contabilizzato',
}

export default function GiftCardsHistory() {
  const { companyId } = useParams<string>();
  const [giftCardsRedeem, setGiftCardsRedeem] = useState<GiftCardsDetails>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    redeemedBy: [],
    shopName: [],
  });
  const priceMultiplier = 100;

  useEffect(() => {
    getRedeemGiftCardsDetailsAPI(companyId)
      .then((giftCardsDetails: GiftCardsDetails) => {
        setGiftCardsRedeem(giftCardsDetails);
        const tempFitlter: FilterOptions = { redeemedBy: [], shopName: [] };
        giftCardsDetails.redeems.forEach((redeem) => {
          if (!tempFitlter.redeemedBy.includes(redeem.redeemedBy)) tempFitlter.redeemedBy.push(redeem.redeemedBy);
          if (!tempFitlter.shopName.includes(redeem.shopName)) tempFitlter.shopName.push(redeem.shopName);
        });

        setFilterOptions(tempFitlter);
      })
      .catch(() => toastWarning('Errore nel carimento delle informazioni relative alle GiftCard. Riprova!'));
  }, [companyId]);

  const setFilter = (columnId: string, filterValue: string) => {
    console.log(columnId);
    console.log(filterValue);
    setColumnFilters((oldFilters) => {
      const updatedFilters = oldFilters.filter((filter) => filter.id !== columnId);
      const parsedValue = filterValue === 'true' ? true : filterValue === 'false' ? false : filterValue;
      if (filterValue) updatedFilters.push({ id: columnId, value: parsedValue });
      return updatedFilters;
    });
  };

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  function formatHour(dateString: string): string {
    const date = new Date(dateString);
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${hours}:${minutes}`;
  }

  const columnHelper = createColumnHelper<RedeemDetails>();
  const columns = [
    columnHelper.accessor('redeemedAt', {
      header: () => 'Data',
      cell: (redeemedAt) => (
        <>
          {formatDate(redeemedAt.getValue())} - {formatHour(redeemedAt.getValue())}
        </>
      ),
      enableSorting: true,
    }),
    columnHelper.accessor('redeemedBy', {
      header: () => 'Riscosso da',
      cell: (redeemedBy) => redeemedBy.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('shopName', {
      header: () => 'Negozio',
      cell: (shopName) => shopName.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('amount', {
      header: () => 'Valore',
      cell: (amount) => <>{amount.getValue() / priceMultiplier}€</>,
      enableSorting: false,
    }),
    columnHelper.accessor('hasBeenTransferred', {
      header: () => 'Stato',
      cell: (hasBeenTransferred) => (
        <>{hasBeenTransferred.getValue() ? TransferredType.ACCOUNTED : TransferredType.UNACCOUNTED}</>
      ),
      enableSorting: false,
    }),
  ];

  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#ffffff',
        borderRadius: '10px',
        border: '1px solid #D4D4D4',
        padding: '20px',
      }}
    >
      <label style={{ color: '#004159', fontWeight: '600', marginBottom: '20px' }}>Gift Cards Riscosse</label>
      <>
        {giftCardsRedeem && giftCardsRedeem.redeems.length !== 0 ? (
          <>
            <GlobalSearch filterValue={globalFilter} onChange={(value) => setGlobalFilter(value)} />
            <FilterContainer>
              <Filter
                columnId="redeemedBy"
                options={filterOptions.redeemedBy}
                placeholder="Riscosso da"
                setFilter={setFilter}
              />
              <Filter
                columnId="shopName"
                options={filterOptions.shopName}
                placeholder="Negozio"
                setFilter={setFilter}
              />
              <FilterBool
                columnId="hasBeenTrasferred"
                placeholder="Trasferibile"
                trueLabel={TransferredType.ACCOUNTED}
                falseLabel={TransferredType.UNACCOUNTED}
                setFilter={setFilter}
              />
            </FilterContainer>
            <CustomTable
              data={giftCardsRedeem.redeems}
              columns={columns}
              pageStartIndex={0}
              pageMaxSize={5}
              columnsVisibility={{}}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
            />
          </>
        ) : (
          <div>Non ci sono Gift Card riscosse al momento.</div>
        )}
      </>
    </div>
  );
}
